import * as React from "react";
import PageLayout from "../../PageLayout";
import { Helmet } from "react-helmet";
import PrevNext from "../../components/PrevNext";
import SectionLayout from "../../SectionLayout";

import background from "../../images/previousprojects/4.jpg"
const Peppimenarti = () => {
    return (
        <PageLayout
            noButton
            image={background}
            navSize={"h-96 xs:h-96"}
            title={"Peppimenarti Power Station – Compact 2000"}
            titleStyles={"xl:mt-16 lg:mt-20 md:mt-16 sm:mt-20 xs:mt-16 mt-8"}
        >
            <Helmet>
                <title>
                    Peppimenarti Power Station - Compact 2000 | EPG Solutions
                </title>
                <meta
                    name="description"
                    content="Client: Power Water Completed: 2019-20 Location: Peppimenarti, Top End region (320km SW of Darwin) Scope: Install and commission a ‘Compact 2000’ fully automated three generator and three feeders control system that monitors and control the generator start/stop, synchronising and load shearing, and generator protection, as well as feeder open/close commands, load sheading, and feeder protection."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"} />
            <SectionLayout bgColor={"bg-epg-white"}>
                <div className={" py-5"}>
                    <div className={"px-2 mb-10  lg:leading-8 lg:text-lg"}>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Client:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Power Water
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Completed:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              2019-20
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Location:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Peppimenarti, Top End region (320km SW of Darwin)
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Scope:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Install and commission a ‘Compact 2000’ fully automated three
              generator and three feeders control system that monitors and
              control the generator start/stop, synchronising and load shearing,
              and generator protection, as well as feeder open/close commands,
              load sheading, and feeder protection. Installation of cable
              support structures, new generator mains, control cabling, remote
              radiator cables, associated field interfaces, and SCADA
              communications.
              <br />
              <br />
              Commissioning and operator training.
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Challenges:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              Remote location, Oppressive heat and humidity.
            </span>
                        </p>
                        <p className={"mt-5 mr-2 font-exo2 font-bold text-epg-black"}>
                            Outcome:
                            <span className={"mt-5 ml-2 font-normal font-exo2 text-epg-black"}>
              On time, on budget, happy customer.
            </span>
                        </p>
                    </div>
                    <PrevNext
                        prev={"fuel-transfer-systems/"}
                        next={"eva-valley-power-station/"}
                    />
                </div>
            </SectionLayout>
        </PageLayout>
    );
};

export default Peppimenarti;
